.tribute-container {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  max-width: 500px;
  overflow: auto;
  display: block;
  z-index: 999999;
  max-height: 160px;
  border-radius: 5px;
  box-shadow: 0 0 4px rgb(0 0 0 / 10%), 0 5px 20px rgb(0 0 0 / 5%);
}
.tribute-container ul {
  margin: 0;
  margin-top: 2px;
  padding: 0;
  list-style: none;
  background: #ffffff;
}
.tribute-container li {
  padding: 6px 15px;
  cursor: pointer;
  width: 200px;
}
.tribute-container li.highlight {
  background: rgb(231, 231, 231);
}
.tribute-container li span {
  font-weight: bold;
}
.tribute-container li.no-match {
  cursor: default;
}
.tribute-container .menu-highlighted {
  font-weight: bold;
}

.trix-content .attachment {
  display: block !important;
}

figure {
  display: flex !important;
}
figure {
  display: flex !important;
}
