body,
h1,
h2,
h3,
h4,
p,
div,
li,
ul,
span {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Notosans",
    Ubuntu, "Droid sans", "Helvetica Neue", sans-serif !important;
}

/* body,
h1,
h2,
h3,
h4,
p,
div,
li,
ul,
span {
  font-family: Segoe UI, sans-serif !important;
} */
