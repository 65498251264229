.workspace-detail {
  background-color: #f4f5f7;
  height: 200px !important;
  content: "";
  width: 100%;
}
.workspace-placeholder {
  height: 90px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 60px;
  color: #fff;
}
.workspace-logo {
  height: 90px;
  width: 90px;
  border-radius: 5px;
  object-fit: cover;
}
.workspace-details-header {
  background-color: #f4f5f7;
}
.workspace-Avatar {
  display: block;
}
.workspace-details-contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0 20px;
}
.workspace-archived {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 650px;
  padding: 10px;
  padding-left: 30px;
  position: relative;
}
.workspace-archived-border {
  border: 1px solid #ecc542;
  padding: 10px;
  background: #fffae5;
  justify-content: center;
}
.workspace-details-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 650px;
  padding: 32px;
  position: relative;
}
.workspace-title {
  font-size: 25px;
  font-weight: 600;
  line-height: 20px;
  margin: 0 0 12px;
}
.workspace-dates {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
  font-weight: 500;
}
.workspace-description {
  font-size: 14px;
  color: gray;
  line-height: 18px;
  margin-bottom: 8px;
}
.edit-workspace {
  color: #172b4d;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Noto Sans", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif;
  width: 210px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  padding: 8px 8px;
  text-decoration: none;
  box-shadow: none;
  border: none;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 85ms;
  transition-timing-function: ease;
}

.workspace-bg {
  background-color: rgba(9, 30, 66, 0.04);
}
.workspace-bg:hover {
  /* background-color: rgba(9, 30, 66, 0.08); */
}

.workspace-action-nav {
  border-bottom: 1px solid #dfe1e6;
  position: relative;
  text-align: center;
  background: #f4f5f7;
}
.workspace-action-nav-items {
  background-color: #dfe1e6;
  border: 1px solid #dfe1e6;
  border-bottom: 0;
  border-radius: 3px 3px 0 0;
  box-sizing: border-box;
  display: inline-block;
  /* font-weight: 700;   */
  margin: 2px 2px 0;
  padding: 8px 34px;
  padding-left: 34px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}
.project-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0 8px;
  margin: 0 auto;
  max-width: 1024px;
}
.nav-button {
  background-color: #dfe1e6;
  border: 1px solid #dfe1e6;
  border-bottom: 0;
  border-radius: 3px 3px 0 0;
  box-sizing: border-box;
  display: inline-block;
  font-weight: 700;
  margin: 2px 2px 0;
  padding: 8px 20px;
  text-decoration: none;
}
.tab-active {
  background-color: #fff !important;
  border-color: #dfe1e6;
  cursor: default;
  margin-bottom: -1px;
  padding-bottom: 9px;
}
.ul-no-margin {
  margin-bottom: 0rem !important;
}
.tab-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0 8px;
  /* margin: 0 auto; */
  /* max-width: 1024px;   */
}
.project-header-heading {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: #757575;
}
.project-search {
  display: flex;
  flex-direction: column;
}
.route-height {
  padding: 0 !important;
}
.project-card {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 112px;
  background: #091e420a;
  font-weight: 400;
  font-size: 16px;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 85ms;
  transition-timing-function: ease;
  cursor: pointer;
  opacity: 0.8;
}
.project-card:hover {
  background-color: #091e4214;
  border: none;
  box-shadow: none;
  color: #172b4d;
}
.project-card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-weight: 600;
  font-size: 15px;
  color: white;
}
.add-project-card {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-weight: 400;
  color: black;
}
.input-search-group {
  border: 2px solid #dee2e6 !important;
  border-radius: 6px;
}
.icon-search-input {
  color: #dee2e6;
  padding-left: 5px;
  align-items: center;
  justify-content: center;
}
.no-border {
  border: none !important;
}
.search-input {
  border-color: none !important;
  outline: none !important;
}
.search-input:focus {
  border: 0 !important;
  box-shadow: none !important;
  outline: none !important;
}
.img-sm {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}
.member-remove {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Noto Sans", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif;
  /* width: 200px; */
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  padding: 8px 8px;
  text-decoration: none;
  box-shadow: none;
  border: none;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 85ms;
  transition-timing-function: ease;
}
.more-option {
  color: white;
  position: absolute;
  right: 20px;
  z-index: 9;
  top: -1px;
  cursor: pointer;
}
.workspace-detail {
  background-color: #f4f5f7;
  height: 200px !important;
  content: "";
  width: 100%;
}
.workspace-placeholder {
  height: 90px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 60px;
  color: #fff;
}
.workspace-logo {
  height: 90px;
  width: 90px;
  border-radius: 5px;
}
.workspace-details-header {
  background-color: #f4f5f7;
}
.workspace-Avatar {
  display: block;
}
.workspace-details-contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0 20px;
}
.workspace-archived {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 650px;
  padding: 10px;
  padding-left: 30px;
  position: relative;
}
.workspace-archived-border {
  border: 1px solid #ecc542;
  padding: 10px;
  background: #fffae5;
  justify-content: center;
}
.workspace-details-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 650px;
  padding: 32px;
  position: relative;
}
.workspace-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  margin: 0 0 12px;
}
.workspace-dates {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
  font-weight: 500;
}
.workspace-description {
  font-size: 14px;
  color: gray;
  line-height: 18px;
  margin-bottom: 8px;
}
.edit-workspace {
  color: #172b4d;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Noto Sans", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif;
  width: 210px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  padding: 8px 8px;
  text-decoration: none;
  box-shadow: none;
  border: none;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 85ms;
  transition-timing-function: ease;
}

.workspace-bg {
  background-color: rgba(9, 30, 66, 0.04);
}
.workspace-bg:hover {
  /* background-color: rgba(9, 30, 66, 0.08); */
}

.workspace-action-nav {
  border-bottom: 1px solid #dfe1e6;
  position: relative;
  text-align: center;
  background: #f4f5f7;
}
.workspace-action-nav-items {
  background-color: #dfe1e6;
  border: 1px solid #dfe1e6;
  border-bottom: 0;
  border-radius: 3px 3px 0 0;
  box-sizing: border-box;
  display: inline-block;
  /* font-weight: 700;   */
  margin: 2px 2px 0;
  padding: 8px 34px;
  padding-left: 34px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}
.project-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0 8px;
  margin: 0 auto;
  max-width: 1024px;
}
.nav-button {
  background-color: #dfe1e6;
  border: 1px solid #dfe1e6;
  border-bottom: 0;
  border-radius: 3px 3px 0 0;
  box-sizing: border-box;
  display: inline-block;
  font-weight: 700;
  margin: 2px 2px 0;
  padding: 8px 20px;
  text-decoration: none;
}
.tab-active {
  background-color: #fff !important;
  border-color: #dfe1e6;
  cursor: default;
  margin-bottom: -1px;
  padding-bottom: 9px;
}
.ul-no-margin {
  margin-bottom: 0rem !important;
}
.tab-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0 8px;
  /* margin: 0 auto; */
  /* max-width: 1024px;   */
}
.project-header-heading {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: #757575;
}
.project-search {
  display: flex;
  flex-direction: column;
}
.route-height {
  padding: 0 !important;
}
.project-card {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 112px;
  background: #091e420a;
  font-weight: 400;
  font-size: 16px;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 85ms;
  transition-timing-function: ease;
  cursor: pointer;
  opacity: 0.8;
}
.project-card:hover {
  background-color: #091e4214;
  border: none;
  box-shadow: none;
  color: #172b4d;
}
.project-card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-weight: 600;
  font-size: 15px;
  color: white;
}
.add-project-card {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-weight: 400;
  color: black;
}
.input-search-group {
  border: 2px solid #dee2e6 !important;
  border-radius: 6px;
}
.icon-search-input {
  color: #dee2e6;
  padding-left: 5px;
  align-items: center;
  justify-content: center;
}
.no-border {
  border: none !important;
}
.search-input {
  border-color: none !important;
  outline: none !important;
}
.search-input:focus {
  border: 0 !important;
  box-shadow: none !important;
  outline: none !important;
}
.img-sm {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  object-fit: cover;
}
.member-remove {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Noto Sans", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif;
  /* width: 200px; */
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  padding: 8px 8px;
  text-decoration: none;
  box-shadow: none;
  border: none;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 85ms;
  transition-timing-function: ease;
}
