.hoverEffectpopover {
  /* padding: 2px; */
  height: 38px;
  width: 38px;
  cursor: pointer;
  border-radius: 5px;
}
.hoverEffectpopover:hover {
  background-color: #f4f5f7;
}
