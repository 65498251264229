.colorWhiteHover:hover {
  color: #fff;
}
.link-underline {
  color: #0073e6;
  cursor: pointer;
}
.link-underline:hover {
  text-decoration: underline;
}
.email-sent,
.i {
  color: #28a745;
}
.login-button {
  justify-content: center;
  border-radius: 8px;
  background: #0073e6;
  padding: 4px 20px;
  flex-grow: 0;
  display: inline-block;
  color: #fff;
  font-weight: 500;
  line-height: 35px;
  font-size: 16px !important;
}
.login-button:hover {
  cursor: pointer;
}
