ul {
  margin: 0;
  padding: 0;
}

ul li {
  color: white;
  font-size: 14px;
}

.weekdays {
  margin: 0;
  padding: 10px 0;
  /* background: #f3f3f3; */
}

.weekdays li {
  display: inline-block;
  width: 14%;
  color: #808080;
  text-align: center;
}

.days {
  padding: 10px 0px 0px 0px !important;
  margin: 0;
}

.days li {
  list-style-type: none;
  display: inline-block;
  width: 14%;
  text-align: center;
  /* margin-bottom: 5px; */
  font-size: 16px;
  font-weight: 600;
  color: #000;
  padding-bottom: 7px !important;
}

.days li .active {
  padding: 5px;
  background: #1abc9c;
  color: white !important;
  padding-bottom: 7px !important;
}

/* Add media queries for smaller screens */
@media screen and (max-width: 720px) {
  .weekdays li,
  .days li {
    width: 14%;
  }
}

@media screen and (max-width: 420px) {
  .weekdays li,
  .days li {
    width: 14%;
  }
  .days li .active {
    padding: 2px;
  }
}

@media screen and (max-width: 290px) {
  .weekdays li,
  .days li {
    width: 14%;
  }
}
