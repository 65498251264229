/* .ql-editor {
  min-height: 95px !important;
  max-height: 200px !important;
} */
.ql-toolbar.ql-snow {
  border: none !important;
}
.ql-container.ql-snow {
  border: none !important;
}
