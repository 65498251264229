.workspace-popover {
  width: 312px !important;
}
.workspace-popover-header {
  text-align: center;
  font-size: 14px;
  color: rgb(128, 128, 128);
  border-bottom: 1px solid rgb(235, 235, 235);
  padding: 5px;
}
.workspace-container {
  padding: 5px;
}
.default-workspace-list {
  display: flex;
  padding: 10px 0px 10px 0px;
}
.workspace-Avatar {
  display: block;
}
.workspace-placeholder- {
  height: 38px;
  width: 38px;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  font-size: 24px;
  color: #fff;
  /* padding-bottom: 12px; */
  font-weight: 600;
  font-style: normal;
  display: flex;
}

.workspace-details-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0 2px;
}
.workspace-details-active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0 2px;
}

.default-list-header {
  font-size: 14px;
  color: rgb(128, 128, 128);
}
