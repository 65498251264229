/** @format */

.workspace-list:hover {
  background-color: rgba(9, 30, 66, 0.08) !important;
  border-radius: 8px;
}

.workspace-project:hover {
  background-color: rgba(9, 30, 66, 0.08) !important;

  border-radius: 8px;
}
.add-workspace:hover {
  background-color: rgba(9, 30, 66, 0.08) !important;
  border-radius: 8px;
}

.workspace-project {
  display: -webkit-box;
  max-width: 15rem !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.workspace-name {
  max-width: 10rem !important;
  /*-webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  text-align: initial;
  line-height: 20px;
  margin: auto; */
}
@media screen and (min-device-width: 990px) and (max-device-width: 1200px) {
  .workspace-name {
    max-width: 8rem !important;
  }
}
.workspace-Avatar {
  margin: 4px 10px 4px 0px;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
  .workspace-project {
    width: 12rem !important;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
  .project-name {
    max-width: 6rem;
  }
}
