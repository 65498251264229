.side-menu-action-nav {
  border-bottom: 1px solid #dfe1e6;
  position: relative;
  text-align: center;
  /* background: #f4f5f7; */
}
.side-menu-action-nav-items {
  /* background-color: #dfe1e6; */
  border: 1px solid #dfe1e6;
  border-bottom: 0;
  border-radius: 3px 3px 0 0;
  box-sizing: border-box;
  display: inline-block;
  /* font-weight: 700;   */
  margin: 2px 2px 0;
  padding: 8px 34px;
  padding-left: 34px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}

.tab-active {
  background-color: #fff !important;
  border-color: #dfe1e6;
  cursor: default;
  margin-bottom: -1px;
  padding-bottom: 9px;
}
.ul-no-margin {
  margin-bottom: 0rem !important;
}
.side-menu-action-nav-items {
  background-color: #dfe1e6;
  border: 1px solid #dfe1e6;
  border-bottom: 0;
  border-radius: 3px 3px 0 0;
  box-sizing: border-box;
  display: inline-block;
  /* font-weight: 700;   */
  margin: 2px 2px 0;
  padding: 8px 34px;
  padding-left: 34px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}
.list-container {
  padding: 15px;
  color: black;
}
.close-icon {
  position: absolute;
  right: 25px;
  font-size: 14px;
  top: 4px;
  width: 28px;
  height: 28px;
  background-color: #f7f8fa;
  color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 4px;
}

.list-card {
  /* box-shadow: 0 1px 1px #091e4240, 0 0 0 1px #091e4214; */
  overflow: hidden;
  padding: 6px 8px 2px;
  background-color: #fff;
  /* border: 1px solid #dfe1e6; */
  border-radius: 3px;
  /* box-shadow: 0 1px 0 #091e4240; */
  display: block;
  margin-bottom: 8px;
  max-width: 300px;
  /* min-height: 50px; */
  position: relative;
  text-decoration: none;
  z-index: 0;
}
.list-card-title {
  word-wrap: break-word;
  clear: both;
  color: #172b4d;
  display: block;
  margin: 0 0 4px;
  overflow: hidden;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
}

.task-container {
  background: rgb(244, 245, 247);
  color: black;
}
.task-list {
  height: 80vh;
  padding: 10px 10px 100px 10px;
  overflow: auto;
}
.task-card {
  background-color: white;
  padding: 8px;
  border-radius: 3px;
  border: 1px solid #dde4eb;
  /* cursor: pointer; */
  /* margin-bottom: 4px; */
}
.task-card-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  /*   
  word-wrap: break-word;
  clear: both;
  color: #172b4d;
  display: block;
  margin: 0 0 4px;
  overflow: hidden;
  text-decoration: none;
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.task-status {
  justify-content: center;
  text-align: center;
  height: 18px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 600;
  font-size: 12px !important;
  opacity: 0.8;
  border-radius: 6px;
  max-width: 300px;
  color: #fff;
}
.task-1 {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.status-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  /* margin: 5px 0; */
}
.task-options {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.icon-space {
  margin-left: 12px;
  font-size: 14px;
  color: #6b778c;
}
.count-space {
  margin-left: 4px;
  color: #afbcca;
  font-size: 12px;
}
