.gray-hover {
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.gray-hover:hover {
  /* background-color: transparent; */
  background-color: #dee2e6;
}

.gray-hover-list {
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 5px;
}
.gray-hover-list:hover {
  /* background-color: transparent; */
  background-color: #dee2e6;
}

.gray-hover-inside {
  /* padding: 2px; */
  height: 36px;
  cursor: pointer;
  border-radius: 5px;
}
.gray-hover-inside:hover {
  background-color: #f4f5f7;
}

#moreoptions:hover {
  padding: 4px 8px;
  border-radius: 5px;
  background: #f4f5f7;
}

#share {
  padding: 4px 8px;
}

#share:hover {
  border-radius: 5px;
  background: #f4f5f7;
}

#Comments {
  padding: 4px 8px;
}
#Comments:hover {
  border-radius: 5px;
  background: #f4f5f7;
}

#viewMode {
  padding: 4px 8px;
}
#viewMode:hover {
  border-radius: 5px;
  background: #f4f5f7;
}

.sidebar-hovericon {
  height: 32px;
  width: 39px;
  cursor: pointer;
  margin-left: 14px;
  position: absolute;
  border-radius: 5px;
}

.sidebar-hovericon:hover {
  background-color: #f4f5f7;
}
.sidebar-doticon {
  height: 20px;
  width: 27px;
  margin-left: 6px;
  cursor: pointer;
  padding: 3px;
}

.task-hover {
  cursor: pointer;
  padding: 0px 5px 0px 5px;
  border-radius: 5px;
}
.task-hover:hover {
  background-color: #f4f5f7;
}
.transition-card {
  transition: 0.1s ease-in-out !important;
  -moz-transition: 0.1s ease-in-out !important; /* Firefox 4 */
  -webkit-transition: 0.1s ease-in-out !important; /* Safari 和 Chrome */
  -o-transition: 0.1s ease-in-out !important; /* Opera */
}

.add-task-card:hover {
  background-color: #f4f5f7 !important;
  border-radius: 5px;
}
