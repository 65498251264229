@font-face {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Notosans",
    Ubuntu, "Droid sans", "Helvetica Neue", sans-serif !important;
  src: url("./../font/Muli-Light.ttf"); /* IE9 Compat Modes */
  src: url("./../font/Muli-Light.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Notosans",
    Ubuntu, "Droid sans", "Helvetica Neue", sans-serif !important;
  src: url("./../font/Muli-Regular.ttf"); /* IE9 Compat Modes */
  src: url("./../font/Muli-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
}
@font-face {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Notosans",
    Ubuntu, "Droid sans", "Helvetica Neue", sans-serif !important;
  src: url("./../font/Helvetica\ 400.ttf"); /* IE9 Compat Modes */
  src: url("./../font/Helvetica\ 400.ttf") format("truetype"); /* Safari, Android, iOS */
}
@font-face {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Notosans",
    Ubuntu, "Droid sans", "Helvetica Neue", sans-serif !important;
  src: url("./../font/Muli-SemiBold.ttf"); /* IE9 Compat Modes */
  src: url("./../font/Muli-SemiBold.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Notosans",
    Ubuntu, "Droid sans", "Helvetica Neue", sans-serif !important;
  src: url("./../font/Muli-SemiBold.ttf"); /* IE9 Compat Modes */
  src: url("./../font/Muli-SemiBold.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Notosans",
    Ubuntu, "Droid sans", "Helvetica Neue", sans-serif !important;
  src: url("./../font/Muli-Bold.ttf"); /* IE9 Compat Modes */
  src: url("./../font/Muli-Bold.ttf") format("truetype"); /* Safari, Android, iOS */
}

// @font-face {
//   font-family: Segoe UI, -sans-serif !important;
//   src: url("./../font/segoeuil.ttf"); /* IE9 Compat Modes */
//   src: url("./../font/segoeuil.ttf") format("truetype"); /* Safari, Android, iOS */
// }

// @font-face {
//   font-family: Segoe UI, sans-serif !important;
//   src: url("./../font/segoeui.ttf"); /* IE9 Compat Modes */
//   src: url("./../font/segoeui.ttf") format("truetype"); /* Safari, Android, iOS */
// }

// @font-face {
//   font-family: Segoe UI, sans-serif !important;
//   src: url("./../font/seguisb.ttf"); /* IE9 Compat Modes */
//   src: url("./../font/seguisb.ttf") format("truetype"); /* Safari, Android, iOS */
// }

// @font-face {
//   font-family: Segoe UI, sans-serif !important;
//   src: url("./../font/seguisb.ttf"); /* IE9 Compat Modes */
//   src: url("./../font/seguisb.ttf") format("truetype"); /* Safari, Android, iOS */
// }

// @font-face {
//   font-family: Segoe UI, sans-serif !important;
//   src: url("./../font/segoeuib.ttf"); /* IE9 Compat Modes */
//   src: url("./../font/segoeuib.ttf") format("truetype"); /* Safari, Android, iOS */
// }
