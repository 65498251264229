body {
  background-color: #ecf9fd;
}

.og-embed {
  max-width: 665px;
  text-align: left;
  border-radius: 1.2rem;
  background-color: #ecf9fd;
  padding: 1em;
  line-height: 1.3em;
  color: rgb(40, 60, 70);
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.og-embed__content {
  /* text-overflow: ellipsis; */
  overflow: hidden;
  margin-top: -15px;
}

:root {
  --color-shade-15: #333; /* Replace with your desired color */
}

.og-embed__title {
  padding: 0 0 0.4rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.og-embed__description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.og-embed__image {
  max-width: 40%;
  /* margin-top: 26px; */
  display: grid;
  align-items: end;
}
