@import "~bootstrap/dist/css/bootstrap-grid.min.css";

html {
  scroll-behavior: smooth;
}
p {
  margin-bottom: 1rem !important;
}
h1 {
  font-weight: 700 !important;
  padding: 10px 0 0 0 !important;
  font-size: 2.6rem !important;
  /* margin-top: 0;*/
  margin-bottom: 0.5rem !important;
  line-height: 1.2 !important;
}

main {
  padding-top: 110px !important;
  font-family: "Noto Sans", sans-serif;
}
li {
  font-size: 1rem !important;
}
ul {
  padding-left: 2rem;
}

body {
  font-family: "Noto Sans", sans-serif;
  transition: 0.5s;
  left: 0;
}
*,
a {
  text-decoration: none !important;
  outline: none !important;
}

.inner-page main {
  padding-top: 110px;
}

header {
  padding: 15px 0;
  top: 0px;
  width: 100%;
  background-color: transparent;
  position: fixed;
  transition: 0.6s;
  z-index: 999;
}

.inner-page .bg-dark {
  background-color: #ffffff !important;
}

.is-scrolled header {
  background-color: #ffffff;
  transition: 0.6s;
  box-shadow: 0.3em 0.5em 1.3em rgba(79, 79, 79, 0.1);
}

.is-scrolled .logo-text {
  color: #0084ff;
}

/* .is-scrolled .nav-item.btn a {
    color: #ffffff;
} */

.is-scrolled .nav-item a {
  color: #ffffff;
}

.is-scrolled .nav-item.btn {
  background-color: #ffffff;
  padding: 5px 20px;
}

.is-scrolled .nav-item.btn a {
  color: #0084ff;
  font-size: 18px;
}

.is-scrolled .whitelogo {
  top: 0px;
  transition: ease top 1000ms, ease margin-left 1000ms;
  padding-top: 0;
}

.whitelogo {
  border-radius: 10px;
}

.logo-text {
  font-size: 26px;
  color: #ffffff;
  display: inline-block;
  margin-left: 8px;
  font-weight: bold;
  vertical-align: middle;
}

.bg-dark {
  background-color: transparent !important;
  padding: 15px 15px 15px 15px;
}

.is-scrolled .bg-dark {
  background: #ffffff !important;
  box-shadow: 0.3em 0.5em 1.3em rgba(79, 79, 79, 0.1);
}
a.navbar-brand.logo h1 {
  color: #0073e6;
  font-weight: 700;
  padding: 10px 0 0 0;
}
.is-scrolled a.navbar-brand.logo h1 {
  padding: 0;
}

footer {
  background-color: #fff;
  padding: 30px 0;
  color: #858585;
  font-size: 18px;
  text-align: center;
}

footer p {
  color: #858585;
  font-size: 16px;
}

.footerlink {
  padding: 10px 0px 10px 10px;
}

.footerlink a {
  position: relative;
  color: #858585;
  padding: 0px 10px 0px 10px;
  font-size: 16px;
}

/* Inner Page */

/* .section-head {
    
} */

.section-head h3 {
  font-size: 36px;
  color: #222;
  text-align: left;
}

.section-head h3::first-letter {
  border-bottom: 3px solid #0073e6;
}

.section-head {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: #000000;
  /* padding-top: 0;
    padding-bottom: 40px; */
  padding: 30px 0;
}

.navbar-expand-md > .container {
  display: block !important;
  text-align: center;
  max-height: 55px;
}
section#steps-to-cancle a {
  color: #0073e6;
  text-decoration: underline !important;
  padding: 0 3px;
}
.footerlink a:after {
  content: "";
  height: 16px;
  width: 1px;
  background: #858585;
  position: absolute;
  right: 0;
  bottom: 2px;
}
.footerlink a:last-child:after {
  display: none;
}
/* .footerlink a{
    position: relative;
} */
/* Media Queries */

@media (max-width: 1199px) {
  .section-head {
    font-size: 40px;
    padding-bottom: 30px;
  }
}

@media (max-width: 992px) {
  .section-head h3 {
    font-size: 24px;
  }
  #steps-to-cancle h3 {
    margin: 0 0 20px 0;
    font-size: 18px;
  }
  footer {
    padding: 10px 0;
  }
  footer p {
    font-size: 16px;
  }
  .headertitle {
    font-size: 33px;
  }
  .section-head {
    font-size: 36px;
  }
  #support {
    padding: 150px 0 20px 0;
  }
  .navbar-expand-md > .container {
    max-height: 45px;
  }
  .inner-page main {
    padding-top: 90px;
  }
}

@media (max-width: 767px) {
  .bg-dark {
    background: #ffffff !important;
  }
  .whitelogo {
    padding-top: 0;
    max-width: 250px;
  }
  .footerlink a {
    font-size: 15px;
  }
  .nav-item.btn {
    max-width: 300px;
    margin: 0 auto;
  }
  footer .d-flex {
    display: block !important;
    text-align: center;
  }
  footer .d-flex > div {
    display: block;
    margin: 10px 0;
  }
  .headertitle {
    font-size: 26px;
    padding: 0px 0px 10px 0px;
  }
  .text-wrap p {
    text-align: center;
    font-size: 16px;
  }
  .custom-reverse {
    flex-direction: column-reverse;
  }
  .inner-page .entry-content p {
    word-break: break-all !important;
  }
  .entry-content .steps-to-cancle p {
    margin: 5px !important;
  }
  p {
    margin-bottom: 1rem !important;
  }
  .inner-page p,
  .inner-page li {
    font-size: 14px;
  }
  .inner-page h4 {
    font-size: 16px;
  }
  .inner-page main {
    padding-top: 85px;
  }
}

@media (max-width: 575px) {
  .is-scrolled .nav-item.btn a {
    padding: 0 20px;
  }
  .navbar-expand-md > .container {
    max-height: 34px;
  }
  .is-scrolled .stiky-header {
    padding: 10px 0;
  }

  footer p {
    font-size: 15px;
  }
  .nav-item.btn a {
    padding: 0 20px;
    line-height: 34px;
  }
  footer {
    padding: 10px 0;
  }
  .nav-item a {
    line-height: 30px;
  }
  .section-head {
    padding-bottom: 40px;
    font-size: 32px;
  }
}
